import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { getPublicConfig, getConfigAsset } from 'apis/config';
import { useConfig, useStore } from 'hooks';

const ErrorContainer = styled.div`
  text-align: center;
  color: #ff4d4f;
`;

export const WaitForConfig = () => {
  const { store, setStore } = useStore();
  const [error, setError] = useState();
  const { setOptions, configOptions } = useConfig();

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      if (ignore) return;
      getPublicConfig()
        .then(async publicConfig => {
          if (ignore) return;
          if (!publicConfig) {
            throw new Error('Empty configuration object returned from API');
          }

          let { faviconUrl, logoUrl } = publicConfig.logo ?? {};

          const promises = [];

          if (faviconUrl && faviconUrl[0] === '/') {
            promises.push(
              getConfigAsset(faviconUrl)
                .then(imageBlob => {
                  faviconUrl = URL.createObjectURL(imageBlob);
                })
                .catch(err => {
                  faviconUrl = null;
                  console.error(err);
                }),
            );
          }

          if (logoUrl && logoUrl[0] === '/') {
            promises.push(
              getConfigAsset(logoUrl)
                .then(imageBlob => {
                  logoUrl = URL.createObjectURL(imageBlob);
                })
                .catch(err => {
                  logoUrl = null;
                  console.error(err);
                }),
            );
          }

          if (promises.length > 0) await Promise.all(promises);
          if (ignore) return;

          const element = document.querySelector("link[rel~='icon']");
          if (faviconUrl) {
            element.href = faviconUrl;
          } else {
            // Leave the favicon empty if no image was found-- this likely means it's been disabled.
            element.href = 'data:,';
          }

          setOptions(publicConfig);
          setStore(s => ({
            ...s,
            config: {
              ...s.config,
              ...publicConfig,
              logoUrl,
              faviconUrl,
              // genAiEnabled is configured as a gradle property, which can
              //   only be referenced as a string. Temporary feature flag.
              genAiEnabled:
                publicConfig.genAiEnabled === 'true' ? true : undefined,
            },
            publicConfigLoaded: true,
            newFile: undefined,
          }));
        })
        .catch(err => {
          setError(
            err?.toString?.() ||
              'Something went wrong while fetching configuration from API',
          );
        });
    };

    fetchData();

    // Refresh app config every 10 minutes
    const intervalId = setInterval(() => {
      fetchData();
    }, 600000);

    return () => {
      ignore = true;
      clearInterval(intervalId);
    };
    // Adding configOptions as a dependency forces the logos to re-render on a config change.
  }, [
    setOptions,
    setStore,
    configOptions.logo?.logoUrl,
    configOptions.logo?.faviconUrl,
    configOptions.logo?.logoHeight,
    store.newFile,
  ]);

  if (error) {
    return <ErrorContainer>{error}</ErrorContainer>;
  }

  if (!store.publicConfigLoaded) {
    return;
  }

  return <Outlet />;
};
