import { useEffect } from 'react';
import { useConfig } from './useConfig';

export const usePageTitle = (pageTitle) => {
  const { configOptions } = useConfig();
  const appShortTitle = configOptions?.title?.titleFav || '4V Nexus';

  useEffect(() => {
    const fullTitle = pageTitle ? `${appShortTitle} - ${pageTitle}` : appShortTitle;
    document.title = fullTitle;
  }, [appShortTitle, pageTitle]);
};
